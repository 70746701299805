const state = { user: null, secret: null};
const getters = {
  isAuthenticated: (state) => !!state.user,
  stateUser: (state) => state.user,
};

const actions = {
  async logIn({commit}, user) { await commit("setUser", user); },
  async logOut({ commit }) { commit("logout"); },
};

const mutations = {
  setUser(state, user) { state.user = user; },
  logout(state) { state.user = null; },
};

export default { state, getters, actions, mutations};
