import { createRouter, createWebHistory, useRouter } from 'vue-router'
import store from "../config/store";

//define a routes
const routes = [
    {
        path: '/', alias:'/home', name: 'home',
        meta: { readyPage:true, requiresAuth: false },
        component: () => import('@/views/Home/Index.vue')
    },
    {
        path: '/pagenotfound', name: 'pagenotfound',
        meta: { readyPage:true, requiresAuth: false },
        component: () => import('@/views/Page/NotFound.vue')
    },

    //--------- ROUTE PAGE ------------//
    {
        path: '/store', name: 'store',
        meta: { readyPage:true, requiresAuth: false },
        component: () => import('@/views/Home/Store.vue')
    },
    {
        path: '/store/:id', name: 'store.detail',
        meta: { readyPage:true, requiresAuth: false },
        component: () => import('@/views/Home/StoreDetail.vue')
    },
    {
        path: '/detailstore/:id', name: 'store.list',
        meta: { readyPage:true, requiresAuth: false },
        component: () => import('@/views/Home/StoreList.vue')
    },
]

//create router
const router = createRouter({ history: createWebHistory(), useroute: useRouter(), routes })

router.beforeEach((to, from, next) => {
    if(to.matched.some((record) => record.meta.readyPage)) {
        next(); return;
    }else{ next('/pagenotfound'); }
});

export default router