<template>
  <div class="nk-block nk-block-middle p-0 mb-3 mt-5">
      <div class="brand-logo text-center">
          <a href="#" class="logo-link">
              <img class="logo-img logo-img-xl mb-2" src="../assets/images/logo.png"  alt="logo">
          </a>
          <a href="https://www.instagram.com/rabbaniprofesorkerudung/" target="_blank" class="main-link-sosmed p-2">@rabbaniprofesorkerudung</a>
      </div>
  </div>
</template>

<script>
  export default {
    computed: {},
  }
</script>