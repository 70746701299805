import { createApp } from "vue";
import Swal from "sweetalert2";

//Style
import "./styles/global.css";
import "./styles/store.css";
import "./styles/responsive.css";

//Route
import Index from "./layout/Index.vue";
import store from "./config/store";
import router from "./config/router";
const app = createApp(Index);
app.use(store);
app.use(router);
app.mount("#app");
