<template>
  <div class="nk-main">
    <div class="nk-wrap nk-wrap-nosidebar">
      <div class="nk-content nk-content-single">
        <div class="container-fluid p-0">
          <div class="nk-content-inner">
            <div class="nk-content-body w-content">
              <Header></Header>
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header';
export default {
  computed: {},
  components: {Header},
  name: "home",
  mounted() {
    document.title = "Toko Rabbani Se-Indonesia";
  },
};
</script>
